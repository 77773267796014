import { Box } from "@mui/material";
import React, { memo } from "react";

import wha from '../../../../assets/svg/whatsapp.svg'
import email from '../../../../assets/svg/mail.svg'

import './MainInfo.scss'

interface MainInfoProps {
    infoResident: any
}

const MainInfo = memo(({infoResident}: MainInfoProps) => {
    return (<div className="main-info">
        <h1>Código de comunidad</h1>
        <span className="code">{infoResident?.community?.code}</span>

        <Box sx={{padding: '10px 0px'}}>
            <p><span>Comunidad: </span>{infoResident?.community?.name}</p>
            <p><span>Contacto: </span>{infoResident?.community?.phone_number}</p>
            <p><span>Correo: </span>{infoResident?.community?.email}</p>
        </Box>
        <Box sx={{padding: '10px 0px'}}>
            <h3>Compartir por</h3>
            <div className="social-logo">
                <a href="">
                    <img src={wha} alt="" />
                </a>
                <a href="">
                    <img src={email} alt="" />
                </a>
            </div>
        </Box>
    </div>)
})

MainInfo.displayName = 'MainInfo'
export default MainInfo