/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { useDecodeToken } from '../helpers/useDecodeToken'
function PrivateRoute(): JSX.Element {
  const { completeToken, validateToken } = useDecodeToken()
  const token: string = completeToken()
  console.log(validateToken(token))
  if (token.length > 0) return <Outlet />
  else return <Navigate to='/' />
}
PrivateRoute.displayName = 'PrivateRoute'
export default PrivateRoute
