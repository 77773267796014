import React, { memo } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import Login from '../views/Auth/Login/Login'
import Home from '../views/Home/Home'
import PrivateRoute from './PrivateRoute'
import Reports from '../views/Reports/Reports'
import Recovery from '../views/Auth/Recovery/Recovery'
import Users from '../views/Users/Users'
import Visits from '../views/Visits/Visits'
import Support from '../views/Support/Support'
import Surveillance from '../views/Surveillance/Surveillance'
import EditPwSurveillance from '../views/Surveillance/Edit/EditSurveillance'
import Statement from '../views/Statement/Statement'
import SupportAuth from '../views/Auth/Support/Support'
import Terms from '../views/Auth/Terms/Terms'
import Privacy from '../views/Auth/Privacy/Privacy'
// import PublicRoute from './PublicRoute'

const Router = memo(() => {
  return (
    <BrowserRouter>
      <Routes>
        
          <Route path='/' element={<Login />} />
          <Route path='/recuperar-contraseña' element={<Recovery />} />
          
          <Route path='/soporte-akcedo' element={<SupportAuth />} />
          <Route path='/terminos-y-condiciones' element={<Terms />} />
          <Route path='/politicas-de-privacidad' element={<Privacy/>}/>

        <Route element={<PrivateRoute />}>
          <Route path='/inicio' element={<Home />} />
          <Route path='/bitacora' element={<Reports />} />
          <Route path='/residentes' element={<Users />} />
          <Route path='/visitas' element={<Visits />} />
          <Route path='/soporte' element={<Support />} />
          <Route path='/vigilancia' element={<Surveillance />} />
          <Route
            path='/vigilancia/asignar-contraseña/:id'
            element={<EditPwSurveillance />}
          />
          <Route path='/comunicados' element={<Statement />} />
        </Route>
        <Route
          path='*'
          element={
            <main style={{ padding: '1rem' }}>
              <p>Url equivocada</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  )
})
Router.displayName = 'Router'
export default Router
