import { SERVICE } from '../config'

export interface loginData {
  email: string
  password: string
}
export interface recoveryData {
  email: string
}

export const login = async (data: loginData): Promise<any> => {
  return await SERVICE().post('/admin/auth/login', data)
}

export const recoveryPassword = async (data: recoveryData): Promise<any> => {
  return await SERVICE().post('/admin/auth/reset-password', data)
}