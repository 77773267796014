import React, { memo } from 'react'
import { Box, FormControl, TextField } from '@mui/material'
import MainButton from '../../../components/MainButton/MainButton'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useSurveillance } from '../hooks/useSurveillance'

interface FormValues {
  name: string
  password: string
  confirmPassword: string
  phone_number: string
  email: string
}

const CreateSurveillance = memo(({ handleClose, getGuardsData }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<FormValues>()
  const { createDataGuard } = useSurveillance()

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const formData = {
      name: data.name,
      email: data.email,
      password: data.password,
      phone_number: data.phone_number
    }
    try {
         await createDataGuard(formData)
      handleClose()
      getGuardsData()
    } catch (error) {
        console.log(error)
    }
   
  }

  const password = watch('password')
  // const confirmPassword = watch('confirmPassword');

  return (
    <Box sx={{ width: '100%', margin: 'auto' }}>
      <h2 style={{ fontWeight: '600' }}>Crear caseta de vigilancia</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          sx={{ width: '100%', marginTop: '15px' }}
          variant='outlined'
        >
          <TextField
            id='name'
            label='Nombre'
            type='text'
            variant='standard'
            {...register('name', { required: 'Este campo es requerido' })}
          />
          {errors.name && (
            <p style={{ color: '#dc2626' }}>{errors.name.message}</p>
          )}
        </FormControl>
        <FormControl
          sx={{ width: '100%', marginTop: '15px' }}
          variant='outlined'
        >
          <TextField
            id='phone_number'
            label='Número de Teléfono'
            type='tel'
            variant='standard'
            {...register('phone_number', {
              required: 'Este campo es requerido',
              pattern: {
                value: /^[0-9]*$/,
                message: 'Ingresa solo números'
              }
            })}
          />
          {errors.phone_number && (
            <p style={{ color: '#dc2626' }}>{errors.phone_number.message}</p>
          )}
        </FormControl>

        <FormControl
          sx={{ width: '100%', marginTop: '15px' }}
          variant='outlined'
        >
          <TextField
            id='email'
            label='Correo Electrónico'
            type='email'
            variant='standard'
            {...register('email', {
              required: 'Este campo es requerido',
              pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
                message: 'Ingresa un correo electrónico válido'
              }
            })}
          />
          {errors.email && <p>{errors.email.message}</p>}
        </FormControl>

        <FormControl
          sx={{ width: '100%', marginTop: '15px' }}
          variant='outlined'
        >
          <TextField
            id='password'
            label='Contraseña'
            type='password'
            variant='standard'
            {...register('password', { required: 'Este campo es requerido' })}
          />
          {errors.password && (
            <p style={{ color: '#dc2626' }}>{errors.password.message}</p>
          )}
        </FormControl>

        <FormControl
          sx={{ width: '100%', marginTop: '15px' }}
          variant='outlined'
        >
          <TextField
            id='confirmPassword'
            label='Confirmar Contraseña'
            type='password'
            variant='standard'
            {...register('confirmPassword', {
              required: 'Este campo es requerido',
              validate: (value) =>
                value === password || 'Las contraseñas no coinciden'
            })}
          />
          {errors.confirmPassword && (
            <p style={{ color: '#dc2626' }}>{errors.confirmPassword.message}</p>
          )}
        </FormControl>

        <Box
          sx={{ width: '30%', margin: 'auto', marginTop: 8, marginBottom: 4 }}
        >
          <MainButton
            type='submit'
            text='Crear'
            color='#023B59'
            onClick={() => {}}
          />
        </Box>
      </form>
    </Box>
  )
})

CreateSurveillance.displayName = 'CreateSurveillance'
export default CreateSurveillance
