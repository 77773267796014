import { useState } from 'react'
// import { useDispatch } from 'react-redux'
import { ErrorToast, SuccessToast } from '../../../components/Toast/Toast'
import { getReportsStatement, sendStatement } from '../../../services/statement/statement'
import { type ReportsData } from '../../Reports/hooks/useReports'

export const useStatement = (): any => {
    const [statementRecovery, setloadingStatement] = useState(false)
    const [statements, setStatement] = useState<ReportsData[]>([])
    const [loadingData, setLoadingData] = useState(false)
    // const [status, setStatus] = useState(0)
    // const dispatch = useDispatch()

    const handleStatement = async (values: any): Promise<any> => {
        setloadingStatement(true)
        try {
            const result = await sendStatement(values)
            console.log(result)
            SuccessToast('Comunicado enviado')
            await getStatement()
            setloadingStatement(false)
        } catch (error: any) {
            console.log(error)
            ErrorToast(error?.response?.data?.message ?? 'Se ha presentado un error')
        }
    }

    const getStatement = async (): Promise<any> => {
        setLoadingData(true)
        try {
            const result = await getReportsStatement()
            console.log(result)
            setStatement(result?.data?.data)
            setLoadingData(false)
        } catch (error: any) {
            ErrorToast(error?.response?.data?.message ?? 'Se ha presentado un error')
            setLoadingData(false)
        }
    }

    return {
        handleStatement,
        statementRecovery,
        loadingData, 
        statements,
        getStatement
    }
}