import { configureStore } from '@reduxjs/toolkit'
import authReducer from './features/auth'

export interface AppStore {
  auth: any
}

export default configureStore<AppStore>({
  reducer: {
    auth: authReducer,
  }
})
