import { useState } from 'react'
import { login, type loginData } from '../../../../services/auth/auth'
import { useDispatch } from 'react-redux'
import { setLoginError, setUserSession } from '../../../../redux/features/auth'
import { ErrorToast, SuccessToast } from '../../../../components/Toast/Toast'
import { useNavigate } from 'react-router-dom'

export const useLogin = (): any => {
    const [loadingLogin, setloadingLogin] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleLogin = async (values: loginData): Promise<any> => {
        setloadingLogin(true)
        dispatch(setLoginError({ error: '' }))
        try {
            const result = await login(values)
            const token: string = result?.data?.token ?? ''
            sessionStorage.setItem('akcedoToken', token)
            dispatch(setUserSession({ token }))
            // autoLogout(token)
            SuccessToast('Bienvenido!')
            navigate('/inicio')
            setloadingLogin(false)
            console.log('Prueba', result)
        } catch (error: any) {
            console.log(error)
            setloadingLogin(false)
            ErrorToast(error?.response?.data?.message ?? 'Eo')
        }
    }

    return {
        handleLogin,
        loadingLogin
    }
}