import React, { memo } from 'react'
import type { SidebarOptionI } from '../../../../types'
import './Sidebar.scss'
import SidebarOption from './SidebarOption/SidebarOption'
import RoddoSiderbarLogo from '../../../../assets/main-logo.png'
import { SidebarTopOptions } from '../../../../constants'
// import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

// import { useHandleLogout } from '../../../../hooks/useHandleLogout'

const renderOptions = (
  options: SidebarOptionI[],
  closed: boolean
): JSX.Element[] => {
  return options.map((res, key: number) => {
    return <SidebarOption key={key} option={res} closed={closed} />
  })
}

const Sidebar = memo(
  ({ closed, handleClick }: { closed: boolean; handleClick: () => void }) => {
    // const {logout} = useHandleLogout('Desconectado')
    // options[1].onClick = logout
    let className = 'sidebar'
    if (closed !== null && closed !== undefined) {
      if (closed) className += ' closed'
    }

    return (
      <div className={className}>
        <div className='top'>
          <div className='logo'>
            {/* <span onClick={handleClick}>
              <MenuOutlinedIcon />
            </span> */}
            <img src={RoddoSiderbarLogo} alt='roddo letters' />
          </div>
          {renderOptions(SidebarTopOptions, closed)}
        </div>
        {/* <div className='bottom'>{renderOptions(options, closed)}</div> */}
      </div>
    )
  }
)
Sidebar.displayName = 'Sidebar'
export default Sidebar
