import { Container } from "@mui/material";
import React, { memo } from "react";

import back from '../../../assets/logos/back.png'

const Terms = memo(() => {
    
    return (<>
    <Container sx={{ paddingTop: 6, paddingBottom: 6 }}>
        <h1 style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '12px' }}> <a href="/"><img width={40} src={back} alt="" /></a>  Términos y condiciones</h1>
            <p>
            Ultima actualización: 21 de agosto de 2023
            </p>
          <p>
          <b>Akcedo Technologies SA de CV</b>
            </p>
          <b>Aceptación de las Condiciones de Uso.</b>
          <p>
            Estas Condiciones de Uso rigen el uso del contenido y las funciones
            disponibles a través de los nombres de dominio akcedo.com así como
            las aplicaciones descargadas desde Play Store y Apple Store
            denominadas Akcedo o Akcedo Guard y cualquier sitio web operado por
            Akcedo Technologies SA de CV. Al utilizar, visitar o navegar el
            Sitio Web y/o aplicaciones, aceptas quedar obligado en virtud de
            estas Condiciones de Uso. Si no estás de acuerdo con estas
            Condiciones de Uso, solicitamos no debes usar el Sitio Web o las
            aplicaciones mencionadas. Estas Condiciones de Uso constituyen un
            contrato continuo entre tú y Akcedo Technologies SA de CV y se
            aplican al uso que hagas de los Sitio Web y aplicaciones antes
            mencionados. Estas Condiciones de Uso afectan tus derechos; por lo
            tanto, debes leerlas con atención. Afirmas que tienes más de 18
            años, y que tienes plena capacidad de aceptar los términos, las
            condiciones, las obligaciones, las afirmaciones, las manifestaciones
            y las garantías que se establecen en estas Condiciones de Servicio,
            y de obligarte en virtud de estas Condiciones de Servicio.
          </p>
          <b>Contenido</b>
          <p>
            Todos los textos, gráficos, interfaces de usuario, interfaces
            visuales, fotografías, marcas comerciales, logotipos, sonidos,
            canciones, ilustraciones y código de programación (en adelante, el
            «contenido»), incluyendo sin limitación, el diseño, estructura,
            selección, coordinación, expresión, estilo y organización de dicho
            contenido, comprendidos por el sitio/app pertenecen a Akcedo
            Technologies SA de CV, que es quien los controla y autoriza, y están
            protegidos por leyes comerciales, derechos de autor, patentes y
            marcas comerciales, así como por otras leyes de derechos de
            propiedad intelectual y de competencia desleal. A menos que estas
            condiciones de uso establezcan lo contrario de manera explícita, no
            está permitido copiar, reproducir, volver a publicar, cargar,
            enviar, exponer públicamente, codificar, traducir, transmitir ni
            distribuir de manera alguna ninguna parte ni contenido del sitio/app
            en ningún otro ordenador, servidor, página web ni en otro medio de
            publicación o distribución sin el consentimiento previo escrito por
            Akcedo Technologies SA de CV. Puedes utilizar la información sobre
            los productos y servicios de Akcedo Technologies SA de CV disponible
            en el sitio/app siempre que (1) no elimines ningún aviso sobre la
            propiedad en las copias de los documentos, (2) utilicen dicha
            información con fines personales y no comerciales, y no realices
            copias ni la publiques en ningún ordenador en red u otro medio, (3)
            no la alteres, y (4) no realices ninguna manifestación o garantía
            adicional con respecto a dichos documentos.
          </p>
          <b>Política de privacidad</b>
          <p>
            Las condiciones de la Política de privacidad de Akcedo Technologies
            SA de CV que se aplican a este sitio/app forman parte de las
            presentes condiciones de uso. Asimismo, al utilizar el sitio/app,
            comprendes y aceptas que las transferencias por Internet podrían no
            ser del todo privadas o 100% seguras. Reconoces la posibilidad de
            que un mensaje o información que envíes al sitio/app pueda ser leído
            o interceptado incluso a pesar de que la misma esté encriptada (por
            ejemplo, la información de una tarjeta de crédito).
          </p>
          <b>Uso del sitio/app</b>
          <p>
            Queda prohibida la utilización de robots, spiders, bots u otros
            dispositivos automáticos, programas, algoritmos, metodologías o
            procesos manuales similares para acceder, adquirir, copiar o hacer
            un seguimiento de parte del sitio/app o su contenido con el fin de
            obtener o intentar obtener materiales, documentos o información no
            disponible a través del mismo. Akcedo Technologies SA de CV se
            reserva el derecho a prohibir cualquier actividad de esta
            naturaleza. Queda prohibido explorar, escanear o evaluar la
            vulnerabilidad del sitio/app o de cualquier red conectada al mismo,
            así como quebrantar las medidas de seguridad o autenticación del
            sitio/app o de cualquier red conectada al mismo. No está permitido
            el rastreo o búsqueda de datos de usuarios, visitantes del sitio/app
            o de otros clientes de Akcedo Technologies SA de CV. Tampoco está
            permitido explotar de manera alguna el sitio/app ni ningún otro
            servicio o información disponible u ofrecida a través del mismo con
            el propósito de revelar información, incluyendo sin limitación, la
            identificación personal o la información que no sea propia
            facilitada por el sitio/app/app. Aceptas no llevar a cabo ninguna
            acción que provoque una saturación desproporcionada o innecesaria en
            la infraestructura del sitio/app o en los sistemas o redes de Akcedo
            Technologies SA de CV. Aceptas no emplear ningún dispositivo,
            programa o rutina con el fin de tratar de interferir en el correcto
            funcionamiento del sitio/app/app o de cualquier transacción llevada
            a cabo en el mismo, así como tampoco en el uso que otras personas
            estén haciendo del sitio/app. No está permitido el uso del
            sitio/app/app ni de su contenido con fines ilegales o ilícitos según
            estas condiciones de uso. Tampoco está permitido encargar la
            realización de ninguna actividad ilegal o que infrinja los derechos
            de Akcedo Technologies SA de CV o de terceros.
          </p>
          <b>Indemnidad</b>
          <p>
            Aceptas indemnizar y mantener indemnes a Akcedo Technologies SA de
            CV, y a sus sociedades controlantes, afiliadas, ejecutivos,
            directores, empleados y agentes, respecto de toda reclamación, daño,
            obligación, pérdida, responsabilidad, costo o deuda, y gasto
            (incluidos por ejemplo, los honorarios de abogados) que surjan de:
            (1) tu acceso al Sitio Web y/o aplicaciones o el uso de estos; (2)
            una violación de tu parte a algún término de estas Condiciones de
            Servicio; (3) una violación de tu parte de un derecho de terceros,
            como por ejemplo, un derecho de autor, de propiedad o de privacidad;
            o (4) toda reclamación de que alguna Información Enviada como
            Usuario por parte tuya es ofensiva, difamatoria, obscena u objetable
            de otro modo, o de que has causado un daño a un tercero. Esta
            obligación de defensa e indemnidad prevalecerá vigente con
            posterioridad a la extinción de estas Condiciones de Servicio y su
            uso del Sitio Web y/o aplicaciones.
          </p>
          <b>Incumplimiento de las condiciones de uso</b>
          <p>
            Si lo considera oportuno, Akcedo podría revelar tus datos personales
            (incluida tu identidad) con el fin de llevar a cabo una
            investigación o reclamación derivada de tu uso del sitio/app, o bien
            para identificar, contactar o emprender medidas legales contra
            alguien que hubiera interferido o causado perjuicios (con intención
            o sin ella) a los derechos de propiedad de Akcedo o de los usuarios
            del sitio/app, incluidos los clientes de Akcedo. Akcedo se reserva
            el derecho a revelar en todo momento cualquier dato que estime
            pertinente para cumplir con las leyes, normativas, procedimientos
            judiciales o solicitudes gubernamentales. Asimismo, Akcedo podría
            revelar tus datos si valora que la normativa vigente así lo
            requiere. Declaras comprender y aceptar que Akcedo podría conservar
            cualquier comunicación contigo o cualquier servicio prestado a
            través del mismo. Asimismo, Akcedo podría revelar estos datos si se
            le requiere legalmente o bien si considera que dicha conservación o
            revelación de datos es razonablemente necesaria con el fin de (1)
            cumplir con procedimientos judiciales, (2) ejecutar las presentes
            condiciones de uso, (3) responder ante demandas sobre la violación
            de dichos datos de los derechos de terceros, o (4) proteger los
            derechos, la propiedad o la seguridad de Ackedo, sus empleados, los
            usuarios o visitantes del sitio/app y el público. Si Akcedo emprende
            alguna acción legal contra ti como consecuencia del incumplimiento
            de las presentes condiciones de uso, tendrá derecho a que le abones
            los gastos de abogados y costes razonables que suponga dicha acción,
            además de cualquier otra indemnización que le corresponda. Aceptas
            que Akcedo no tenga ninguna responsabilidad ante ti ni ante ningún
            tercero respecto a la rescisión del acceso al sitio/app como
            consecuencia del incumplimiento de las condiciones de uso.
          </p>
        </Container>
    </>)
})

Terms.displayName = 'Terms'
export default Terms;