import axios, { type AxiosInstance } from 'axios'
const baseURL = process.env.REACT_APP_API ?? 'http://localhost:3001/api'

const getTokenData = (): string | null => {
  const TOKEN_PART = sessionStorage.getItem('akcedoToken') ?? ''
  return `${TOKEN_PART}`
}
const headers = {
  'Content-Type': 'application/json; charset=utf-8',
  Authorization: ''
}

const SERVICE = (): AxiosInstance => axios.create({ baseURL, headers })

const AUTHSERVICE = (): AxiosInstance => {
  const token = getTokenData()
  let authHeaders = headers
  if (token !== null) {
    authHeaders = {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Bearer ${token}`
    }
  }
  return axios.create({ baseURL, headers: authHeaders })
}

export { SERVICE, AUTHSERVICE }
