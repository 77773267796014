import { useState } from 'react'
import { useHandleLogout } from '../../../hooks/useHandleLogout'
import { getTypesReports, getReports } from '../../../services/reports/reports'

export interface TypeDataReport {
    id: number
    name: string
}

export interface ReportsData {
    id: number
    title: string
    description: string
    sender: {
        name: string
        email: string
        phone_number: string
    },
    notification_type: {
        name: string
    },
    created_at: string
}
export const useReports = (): any => {
    const [typeReports, setTypeReport] = useState<TypeDataReport[]>([])
    const [reports, setReports] = useState<ReportsData[]>([])
    const [loadingType, setLoadingType] = useState<boolean>(false)
    const [loadingReports, setLoadingReports] = useState<boolean>(false)
    const [error, setError] = useState<any>();
    const {logout} = useHandleLogout()

    const getDataTypeReports = async (): Promise<any> => {
        setLoadingType(true)
        try {
            const result = await getTypesReports()
            setTypeReport(result?.data)
        } catch (error: any) {
            setError(error)
            if (error.message.includes('401')) {
                return logout()
              }
        }
        setLoadingType(false)
    }

    const getReportsData = async (filters: any): Promise<any> => {
        setLoadingReports(true)
         try {
            const result = await getReports(filters)
            setReports(result?.data?.data)
            setLoadingReports(false)
         } catch (error) {
            console.log(error)
            setLoadingReports(false)
         }
    }
    return {
        typeReports,
        error,
        loadingType,
        loadingReports,
        reports,
        getDataTypeReports,
        getReportsData
    }
}