/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ErrorToast } from '../components/Toast/Toast'
import { useDecodeToken } from '../helpers/useDecodeToken'
import { type AuthData, setUserSession } from '../redux/features/auth'
import { type AppStore } from '../redux/store'

export const useHandleLogout = (Message = 'La sesión caducó'): any => {
  const { decodeTokenData } = useDecodeToken()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { expireToken }: AuthData = useSelector((store: AppStore) => store.auth)
  /* 
Debido a que este auto logout esta dentro de un custom hook, la unica forma de poder limpiar el intervalo es dentro del mismo componente, como autoLogout y logout son llamados en diferentes componentes el "clearInterval()" nunca encuentra el intervalo debido a que para cada componente el custom hook es unico.

Entonces para que  la funcion de autoLogout se ejecute necesitamos usar useEffect. El useEffect espera por un valor de tiempo que mientras se encuentre en el login no existe, pero una vez q el usuario se loggea, se guarda el expire token y como logout se llama en un componente global (en este caso el sidebar), se ejecutara la funcion de timeout una vez pase el tiempo.

NOTA:
Es importante colocar el clearTimout en caso de que el: custom hook useHandleLogout; sea llamado en diferentes components para q los intervalos no se stackeen

NO hay problemas si el usuario decide hacer logout puesto que el componente que llama a esa funcion dejara de estar en la vista y el useEffect en ese momento limpiara el intervalo
*/

  useEffect(() => {
    if (typeof expireToken === 'number') {
      const TIMER = setTimeout(() => {
        logout()
      }, expireToken)
      return () => {
        clearTimeout(TIMER)
      }
    }
  }, [expireToken])

  const logout = (): void => {
    sessionStorage.removeItem('akcedoToken')
    dispatch(setUserSession({ token: '', expireToken: undefined }))
    navigate('/')
    ErrorToast(Message)
  }

  const autoLogout = (token: string): void => {
    const userData = decodeTokenData(token)
    const expirationDate = new Date(userData.exp * 1000).getTime()
    const date = new Date().getTime()
    const logoutTime = expirationDate - date
    dispatch(setUserSession({ expireToken: logoutTime }))
  }

  return { logout, autoLogout }
}
