import { useState } from 'react'
import { useHandleLogout } from '../../../hooks/useHandleLogout'
import { getUserByStatus, updatedStatusUser, getUserChart } from '../../../services/users/user';
import { ErrorToast, SuccessToast } from '../../../components/Toast/Toast'

export interface UserData {
    code: string;
    date: string;
    identification: string;
    name: string;
    reason: string;
    type: string;
    to?: string;
}
export const useUsersApp = (): any => {
    const [userStatus, setUsersStatus] = useState<UserData[]>([])
    const [userChart, setUserChart] = useState<UserData[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<any>();
    const {logout} = useHandleLogout()

    const userByStatus = async (status: string): Promise<any> => {
        setLoading(true)
        try {
            const result = await getUserByStatus(status)
            setUsersStatus(result?.data?.data)
        } catch (error: any) {
            setError(error)
            if (error.message.includes('401')) {
                return logout()
              }
        }
        setLoading(false)
    }

    const handleUpdateStatus = async (id: number, data: any): Promise<any> => {
        try {
            await updatedStatusUser(id, data)
            SuccessToast('Usuario actualizado correctamente')
        } catch (error: any) {
            console.log(error); 
            ErrorToast(error?.response?.data?.message ?? 'Error, favor intentarlo más tarde')
        }
    }
    const getUserChartData = async (): Promise<any> => {
        setLoading(true)
        try {
            const result = await getUserChart()
            console.log(result.data)
            setUserChart(result?.data)
        } catch (error: any) {
            setError(error)
            if (error.message.includes('401')) {
                return logout()
              }
        }
        setLoading(false)
    }
    return {
        userStatus,
        userChart,
        error,
        loading,
        userByStatus,
        handleUpdateStatus,
        getUserChartData
    }
}