import { useState } from 'react'
import { useHandleLogout } from '../../../hooks/useHandleLogout'
import { ErrorToast, SuccessToast } from '../../../components/Toast/Toast'
import { sendMessage } from '../../../services/support/support'


export const useSupport = (): any => { 
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<any>();

    const {logout} = useHandleLogout()


    const sendSupportMessage = async (data: any): Promise<any> => {
        try {
            const result = await sendMessage(data)
            if (result?.status === 200) {
                SuccessToast('Consulta enviada, pronto se comunicaran contigo!')
            }
        } catch (error: any) {
            setError(error)
            if (error.message.includes('401')) {
                return logout()
              }
              ErrorToast(error?.response?.data?.message ?? 'Error, intentelo mas tarde')
        }
        setLoading(false)
    }

    return {
        error,
        loading,
        sendSupportMessage
    }
}