import { useState } from 'react'
import { recoveryPassword, type recoveryData } from '../../../../services/auth/auth'
import { useDispatch } from 'react-redux'
import { setLoginError } from '../../../../redux/features/auth'
import { ErrorToast, SuccessToast } from '../../../../components/Toast/Toast'

export const useRecovery = (): any => {
    const [loadingRecovery, setloadingRecovery] = useState(false)
    const [status, setStatus] = useState(0)
    const dispatch = useDispatch()

    const handleRecovery = async (values: recoveryData): Promise<any> => {
        setloadingRecovery(true)
        dispatch(setLoginError({ error: '' }))
        try {
            const result = await recoveryPassword(values)
            console.log(result)
            SuccessToast(result?.data?.message)
            setStatus(result?.status)
            setloadingRecovery(false)
        } catch (error: any) {
            console.log(error)
            ErrorToast(error?.response?.data?.message ?? 'Se ha presentado un error')
        }
    }

    return {
        handleRecovery,
        loadingRecovery,
        status
    }
}