import { Box } from "@mui/material";
import React, { memo } from "react";

import './CardInfo.scss'

interface CardInfoProps {
    title: string
    children: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[]
}

const CardInfo = memo(({title, children}: CardInfoProps) => {
    return (<div className="card-info">
        <h1>{title}</h1>
        <Box sx={{ width: '100%' }}>
            {children}
        </Box>  
    </div>)
})

CardInfo.displayName = 'CardInfo'
export default CardInfo