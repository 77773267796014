/* eslint-disable @typescript-eslint/promise-function-async */
import React, { memo, Suspense } from 'react'

import Navbar from './components/Navbar/Navbar'
import Sidebar from './components/Sidebar/Sidebar'
import { useHomeTemplate } from './hooks/useHomeTemplate'
import './HomeTemplate.scss'
interface HomeTemplateProps {
  children: React.ReactNode | JSX.Element | JSX.Element[] | React.ReactNode[]
  size?: 'regular' | 'strech'
}
const HomeTemplate = memo(
  ({ children, size = 'regular' }: HomeTemplateProps) => {
    const {
      closed,
      className,
      handleClickSidebar,
      getNavbarTitle,
    } = useHomeTemplate()

    return (
      <div className='main-layout'>
        <Sidebar closed={closed} handleClick={handleClickSidebar} />
        <div className={className}>
          <Navbar title={getNavbarTitle()} />
          <div className={`main-body ${size}`}>{children}</div>
        </div>
        <Suspense fallback={null}>

        </Suspense>
      </div>
    )
  }
)
HomeTemplate.displayName = 'HomeTemplate'
export default HomeTemplate
