import React, { memo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import HomeTemplate from '../../template/HomeTemplate/HomeTemplate';
import './Support.scss';
import Wrapped from '../../components/Wrapped/Wrapped';
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Box } from '@mui/material';
import MainButton from '../../components/MainButton/MainButton';
import { useSupport } from './hook/useSupport';

interface FormData {
  description: string;
}

const Support = memo(() => {
  const {sendSupportMessage} = useSupport()
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<FormData>({
    resolver: yupResolver(
      yup
        .object({
          description: yup.string().required('Es requerido escribir un mensaje'),
        })
        .required()
    ), defaultValues: {
      description: ''
    }
  })

  const onSubmit = async (data: FormData): Promise<any> => {
    const dataForm = {
      description: data.description,
    };
    
    // Llamar a la función para enviar el mensaje
    await sendSupportMessage(dataForm);
    
    // Restablecer el formulario después de enviarlo
    reset();
  };

  return (
    <HomeTemplate>
      <Wrapped title="Bienvenido a soporte Akcedo">
        <Box
          sx={{
            width: '80%',
            margin: 'auto',
            height: '80%',
            overflowY: 'auto',
            padding: '10px',
          }}
        >
          <div>
            <h3>
              ¿Que hacer si denegué el acceso a “mi comunidad” a una persona de
              mi comunidad?
            </h3>
            <p>
              En el caso de denegar/eliminar a una persona que debe de ser parte
              de su comunidad, debe enviarnos un correo con el nombre y numero
              de casa de la persona a la que se le negó el acceso.
            </p>
          </div>

          <div>
            <h3>¿Como contactarme con Atención al cliente?</h3>
            <p>
              Puede contactar a nuestros agentes de atención al cliente por
              medio de los diferentes medios:
            </p>
          </div>
          <div>
            <p>
              <b>Correo eléctronico: </b>
              <a href="mailto:soporte@akcedo.com">soporte@akcedo.com</a>
            </p>
            <p>
              <b>Teléfono: </b>+503 000-0000
            </p>
            <p>
              <b>WhatsApp: </b>+503 7777-7711
            </p>
          </div>
        </Box>
        <Box
          sx={{
            width: '80%',
            margin: 'auto',
            height: '80%',
            overflowY: 'auto',
            padding: '10px',
          }}
        >
          <h3>¿No encontraste tu problema acá?</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
                  name='description'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <textarea
                      placeholder='Escribe el mensaje...'
                      onChange={onChange}
                      value={value}
                      style={{
                        width: '100%',
                        borderRadius: '6px',
                        borderColor: '#bababa',
                        resize: 'none',
                        padding: '10px'
                      }}
                      id=''
                      cols={30}
                      rows={10}
                    />
                  )}
                />
                {errors?.description?.message ? (
                  <p style={{ color: '#dc2626' }}>{errors.description.message}</p>
                ) : null}
            <div style={{ width: '200px', marginTop: '10px' }}>
              <MainButton type="submit" text="Enviar" color="#023B59" onClick={()=> {}} />
            </div>
          </form>
        </Box>
      </Wrapped>
    </HomeTemplate>
  );
});

Support.displayName = 'Support';
export default Support;
