import { useState } from 'react';
import { getVisitWithFilters } from '../../../services/visits/visits';
import { ErrorToast } from '../../../components/Toast/Toast';

export interface VisitData {
    id: number;
      code: string;
      date: string;
      name: string;
      type: string;
      user: {
        id: number;
        name: string;
        email: string;
        phone_number: string;
      },
      reason: string;
      identification: string;
}
export const useVisit = (): any => {
    const [visits, setVisits] = useState<VisitData[]>([]);
    const [loading, setLoading] = useState<boolean>(false)

    const getVisitsData = async (filters: any): Promise<any> => {
        setLoading(true)
        try {
            const result = await getVisitWithFilters(filters)
            setVisits(result?.data?.data)
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            ErrorToast(error?.response?.data?.message ?? 'Error, favor intentarlo más tarde')
        }
    }
    return {
        visits,
        loading,
        getVisitsData
    }
}