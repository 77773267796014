import React, { memo, useEffect, useState } from 'react'
import HomeTemplate from '../../template/HomeTemplate/HomeTemplate'
import dayjs, { type Dayjs } from 'dayjs'

import './Reports.scss'
import Wrapped from '../../components/Wrapped/Wrapped'
import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import MainButton from '../../components/MainButton/MainButton'
import CardInfo from '../../components/CardInfo/CardInfo'
import { type TypeDataReport, useReports, type ReportsData } from './hooks/useReports'
import load from '../../assets/loading.gif'

const Reports = memo(() => {
  const {
    getReportsData,
    reports,
    loadingReports,
    getDataTypeReports,
    typeReports,
    loadingType
  } = useReports()
  const [value, setValue] = useState<Dayjs | null>()
  const [filters] = useState({})
  const [valueTwo, setValueTwo] = useState<Dayjs | null>()
  const [age, setAge] = React.useState('')

  const handleChange = (event: SelectChangeEvent): void => {
    console.log(event)
    setAge(event.target.value )
  }

  useEffect(() => {
    getDataTypeReports()
  }, [])

  useEffect(() => {
    getReportsData(filters)
  }, [])


  const sendFilters = (): void => {
    const data: Record<string, string> = {}; // Usamos un tipo específico para el objeto data
    
    if (valueTwo) {
      data.end_date = dayjs(valueTwo).format('YYYY-MM-DD');
    }
  
    if (value) {
      data.start_date = dayjs(value).format('YYYY-MM-DD');
    }
  
    if (age) {
      data.type = age;
    }
    
    if (Object.keys(data).length === 0) {
      console.log("No hay datos para enviar.");
      return;
    }
    getReportsData(data);
  };
  return (
    <HomeTemplate>
      <Box
        sx={{
          background: 'white',
          padding: '10px 10px',
          borderRadius: '25px',
          marginBottom: '20px'
        }}
      >
        <Container maxWidth='lg'>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                    label='Fecha inicial'
                    value={value}
                    maxDate={dayjs()}
                    onChange={(newValue) => {
                      setValue(newValue)
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                    label='Fecha final'
                    maxDate={dayjs()}
                    value={valueTwo}
                    onChange={(newValue) => {
                      setValueTwo(newValue)
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={{ marginTop: 1, width: '100%' }}>
                <InputLabel id='demo-simple-select-helper-label'>
                  Busqueda avanzada
                </InputLabel>
                {!loadingType && (
                <Select
                  labelId='demo-simple-select-helper-label'
                  id='demo-simple-select-helper'
                  value={age}
                  label='Busqueda avanzada'
                  onChange={handleChange}
                >
                  
                      {typeReports?.map(
                        (item: TypeDataReport, index: number) => (
                          <MenuItem key={index} value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        )
                      )}
                  
                </Select>

                ) }
                
              </FormControl>
            </Grid>
            <Grid item xs={3} sx={{}}>
              <Box sx={{ paddingLeft: '25px', marginTop: '10px' }}>
                <MainButton
                  onClick={sendFilters}
                  text='Buscar'
                  color='#023B59'
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Wrapped title='Reportes'>
        <Box
          sx={{
            width: '80%',
            margin: 'auto',
            height: '80%',
            overflowY: 'auto',
            padding: '10px'
          }}
        >
          {!loadingReports ? (<>
          
          {
            reports?.map((item: ReportsData, index: number) => (
            <>
              <Grid
                key={index}
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
              >
                {' '}
                <div style={{width:'75%'}}>
                  <CardInfo key={index} title={item?.notification_type?.name}>
                  <p>
                    <span>De: </span>
                    {item?.sender?.name}
                  </p>
                  <p>
                    <span>Asunto: </span>
                    {item?.title}
                  </p>
                  <p>
                    <span>Mensaje: </span>
                    {item?.description}
                  </p>
                </CardInfo>
                </div>
                
                <div className='date-data'>
                  <p>{dayjs(item?.created_at).format('DD/MM/YYYY') }</p>
                </div>
              </Grid>
              <hr />
            </>
          ))}
          
          </> ) : (
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <img width={60} src={load} alt="load" />
         </Box>
          )}
        </Box>
      </Wrapped>
    </HomeTemplate>
  )
})

Reports.displayName = 'Reports'
export default Reports
