import React, { memo, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import AuthTemplate from '../../../template/AuthTemplate/AuthTemplate'
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'

import './Login.scss'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import MainButton from '../../../components/MainButton/MainButton'
import { useLogin } from './hooks/useLogin'

interface LoginForm {
  email: string
  password: string
}
const Login = memo(() => {
  const [showPassword, setShowPassword] = useState(false)
  const { handleLogin } = useLogin()

  const handleClickShowPassword = (): void => {
    setShowPassword((show) => !show)
  }
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault()
  }

  // Form
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<LoginForm>({
    resolver: yupResolver(
      yup
        .object({
          email: yup.string().required('El email es requerido'),
          password: yup.string().required('La contraseña es requerida')
        })
        .required()
    )
  })

  const handleClick = (): void => {}

  const sendDataLogin = (form: LoginForm): void => {
    handleLogin(form)
  }

  return (
    <AuthTemplate maxSize='small'>
      <form onSubmit={handleSubmit(sendDataLogin)}>
        <div className='login'>
          <Box sx={{ width: '100%' }}>
            <FormControl sx={{ width: '100%' }} variant='outlined'>
              <Controller
                name='email'
                control={control}
                render={({ field: { onChange, value }, formState }) => (
                  <TextField
                    helperText={
                      errors?.email?.message ? errors.email?.message : null
                    }
                    error={!!errors?.email?.message}
                    onChange={onChange}
                    value={value}
                    type='email'
                    label='Correo'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <EmailOutlinedIcon />
                        </InputAdornment>
                      )
                    }}
                    variant='standard'
                  />
                )}
              />
            </FormControl>

            <FormControl
              sx={{ width: '100%', marginTop: '1.75rem' }}
              variant='outlined'
            >
              <Controller
                name='password'
                control={control}
                render={({ field: { onChange, value }, formState }) => (
                  <TextField
                    helperText={
                      errors?.password?.message
                        ? errors?.password?.message
                        : null
                    }
                    error={!!errors?.password?.message}
                    onChange={onChange}
                    value={value}
                    type={showPassword ? 'text' : 'password'}
                    label='Correo'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    variant='standard'
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box sx={{ width: '100%', marginTop: 8 }}>
            <MainButton
              onClick={handleClick}
              text='Iniciar sesión'
              color='#023B59'
              type='submit'
            />
          </Box>
          <Box sx={{ marginTop: 5 }}>
            <a href='/recuperar-contraseña'>Olvidaste contraseña?</a>
          </Box>
        </div>
      </form>
    </AuthTemplate>
  )
})

Login.displayName = 'Login'
export default Login
