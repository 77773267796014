import { useState } from 'react'
import { useHandleLogout } from '../../../hooks/useHandleLogout'
import { getVisitData } from '../../../services/visits/visits'
import { getReports } from '../../../services/reports/reports'
import { getUserChart, getUserProfile } from '../../../services/users/user'

export interface VisitData {
    code: string;
    date: string;
    identification: string;
    name: string;
    reason: string;
    type: string;
    to?: string;
}
export const useHomeServices = (): any => {
    const [visits, setVisits] = useState<VisitData[]>([])
    const [reports, setReports] = useState<VisitData[]>([])
    const [userChart, setUserChart] = useState<VisitData[]>([])
    const [userProfile, setUserProfile] = useState<VisitData[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<any>();
    const {logout} = useHandleLogout()

    const getVisitsData = async (): Promise<any> => {
        setLoading(true)
        try {
            const result = await getVisitData()
            console.log(result.data)
            setVisits(result?.data?.data)
        } catch (error: any) {
            setError(error)
            if (error.message.includes('401')) {
                return logout()
              }
        }
        setLoading(false)
    }
    const getReportsData = async (filters: any): Promise<any> => {
        setLoading(true)
        try {
            const result = await getReports(filters)
            console.log(result.data)
            setReports(result?.data?.data)
        } catch (error: any) {
            setError(error)
            if (error.message.includes('401')) {
                return logout()
              }
        }
        setLoading(false)
    }
    const getUserChartData = async (): Promise<any> => {
        setLoading(true)
        try {
            const result = await getUserChart()
            console.log(result.data)
            setUserChart(result?.data)
        } catch (error: any) {
            setError(error)
            if (error.message.includes('401')) {
                return logout()
              }
        }
        setLoading(false)
    }
    const getUserProfileData = async (): Promise<any> => {
        setLoading(true)
        try {
            const result = await getUserProfile()
            console.log(result.data)
            setUserProfile(result?.data?.data)
        } catch (error: any) {
            setError(error)
            if (error.message.includes('401')) {
                return logout()
              }
        }
        setLoading(false)
    }
    return {
        visits,
        reports,
        userChart,
        userProfile,
        error,
        loading,
        getVisitsData,
        getReportsData,
        getUserChartData,
        getUserProfileData
    }
}