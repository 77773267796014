import { useState } from 'react'
import { useHandleLogout } from '../../../hooks/useHandleLogout'
import { createDataGuards, getDataGuards, getGuardId, updatePwGuard } from '../../../services/surveillance/surveillance'
import { ErrorToast, SuccessToast } from '../../../components/Toast/Toast'
import { useNavigate } from 'react-router-dom'


export const useSurveillance = (): any => { 
    const [guards, setGuardData] = useState<any[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<any>();
    const [status, setStatus] = useState(0)
    const {logout} = useHandleLogout()

    const navigate = useNavigate()

    const getGuardsData = async (): Promise<any> => {
        setLoading(true)
        try {
            const result = await getDataGuards()
            setGuardData(result?.data?.data)
        } catch (error: any) {
            setError(error)
            if (error.message.includes('401')) {
                return logout()
              }
        }
        setLoading(false)
    }

    const createDataGuard = async (data: any): Promise<any> => {
        try {
            const result = await createDataGuards(data)
            setStatus(result?.status)
            console.log(result)
            if (result?.status === 201) {
                SuccessToast('Caseta creada exitosamente!')
                await getGuardsData()
            }
        } catch (error: any) {
            setError(error)
            if (error.message.includes('401')) {
                return logout()
              }
              ErrorToast(error?.response?.data?.message ?? 'Error, intentelo mas tarde')
        }
        setLoading(false)
    }

    const getGuardById = async (id: string): Promise<any> => {
        try {
            const result = await getGuardId(id)
            console.log(result)
        } catch (error) {
            console.log(error)
        }
    }

    const updatePassword = async (id: string, data: string): Promise<any> => {
        try {
            const result = await updatePwGuard(id, data)
            if (result?.status === 200) {
                SuccessToast('Contraseña actualizada exitosamente')
                navigate('/vigilancia')
            }
        } catch (error: any) {
            ErrorToast(error?.response?.data?.message ?? 'Error, intentelo mas tarde')
        }
    }

    return {
        guards,
        error,
        loading,
        status,
        getGuardsData,
        createDataGuard,
        getGuardById,
        updatePassword
    }
}