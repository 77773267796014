import React, { memo } from 'react'
import AuthTemplate from '../../../template/AuthTemplate/AuthTemplate'
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  TextField
} from '@mui/material'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'

import './Recovery.scss'
import MainButton from '../../../components/MainButton/MainButton'
import { useNavigate } from 'react-router-dom'
import { useRecovery } from './hooks/useRecovery'
import { type recoveryData } from '../../../services/auth/auth'

interface RecoveryForm {
  email: string
}

const Recovery = memo(() => {
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<RecoveryForm>({
    resolver: yupResolver(
      yup
        .object({
          email: yup.string().required('El email es requerido')
        })
        .required()
    )
  })

  const { handleRecovery, status } = useRecovery()

  const navigate = useNavigate()

  const goToCancel = (): void => {
    navigate('/')
  }
  const sendData = (form: recoveryData): void => {
    handleRecovery(form)
  }

  const handleClick = (): void => {

  } 


  return (
    <AuthTemplate maxSize='small'>
      {
        status === 200 ? (<div className='recovery'>
          <Box>
            <Grid container spacing={3}>
            <Grid item xs={12} sx={{ marginTop: '30px', marginBottom: '30px', padding: 0 }}>
               <p style={{color: '#000', textAlign:'center'}}><b>Correo enviado con éxito</b> </p>
              </Grid>
              <Grid item xs={12}>
                <MainButton
                  type='submit'
                  onClick={goToCancel}
                  color='#023B59'
                  text='Ir a login'
                />
              </Grid>
            </Grid>
          </Box>
      </div>) : (<div className='recovery'>
        <form onSubmit={handleSubmit(sendData)}>
          <Box sx={{ width: '100%' }}>
            <FormControl sx={{ width: '100%' }} variant='outlined'>
              <Controller
                name='email'
                control={control}
                render={({ field: { onChange, value }, formState }) => (
                  <TextField
                    helperText={
                      errors?.email?.message ? errors.email?.message : null
                    }
                    error={!!errors?.email?.message}
                    onChange={onChange}
                    value={value}
                    type='email'
                    label='Correo'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <EmailOutlinedIcon />
                        </InputAdornment>
                      )
                    }}
                    variant='standard'
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box sx={{ marginTop: '8rem' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MainButton
                  type='submit'
                  onClick={handleClick}
                  color='#023B59'
                  text='Enviar'
                />
              </Grid>
              <Grid item xs={12}>
                <MainButton
                  onClick={goToCancel}
                  color='#340283'
                  text='Cancelar'
                />
              </Grid>
            </Grid>
          </Box>
        </form>
      </div>
      )
      }
      
    </AuthTemplate>
  )
})

Recovery.displayName = 'Recovery'
export default Recovery
