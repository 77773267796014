import React, { memo } from "react";
import logo from '../../../../assets/main-logo.png'
import './HeaderAuth.scss';

const HeaderAuth = memo(() => {
    return (
        <div className="header_auth">
            <img src={logo} alt="" />
            <p>Bienvenido a Akcedo administrador</p>
        </div>
    )
})

HeaderAuth.displayName = 'HeaderAuth'
export default HeaderAuth