import { AUTHSERVICE } from '../config'

export const getVisitData = async (): Promise<any> => {
    return await AUTHSERVICE().get('/admin/visit/')
  }

export const getVisitWithFilters = async (filters: Record<string, string>): Promise<any> => {
    const queryParams = new URLSearchParams(filters).toString();
    console.log(queryParams)
    return await AUTHSERVICE().get(`/admin/visit?${queryParams}`)
}