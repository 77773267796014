import React, { memo, useEffect, useState } from 'react'
import HomeTemplate from '../../template/HomeTemplate/HomeTemplate'
import dayjs, { type Dayjs } from 'dayjs'

import './Visits.scss'
import Wrapped from '../../components/Wrapped/Wrapped'
import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tabs,
  type SelectChangeEvent,
  Tab
} from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import MainButton from '../../components/MainButton/MainButton'
import CardInfo from '../../components/CardInfo/CardInfo'
import { type VisitData, useVisit } from './hooks/useVisits'
import { ErrorToast } from '../../components/Toast/Toast'
import load from '../../assets/loading.gif'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const Visits = memo(() => {
  const [value, setValue] = useState<Dayjs | null>()
  const [filters, setFilters] = useState({})
  const [valueTwo, setValueTwo] = useState<Dayjs | null>()
  const [age, setAge] = React.useState('')
  const [valueTab, setValueTab] = React.useState(0)
  const { visits, loading, getVisitsData } = useVisit()

  const handleChange = (event: SelectChangeEvent): void => {
    setAge(event.target.value)
  }
  const handleChangeTab = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => {
    setValueTab(newValue)
    if(newValue === 1) {
      const data: Record<string, string> = {}
      data.isActive = 'false'
      getVisitsData(data)
      setFilters({ isActive: 'false' })
    } else {
      getVisitsData({})
      setFilters({})
    }
  }

  const CustomTabPanel: React.FC<TabPanelProps> = (props) => {
    const { children, value, index, ...other } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    )
  }

  const a11yProps: any = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  const sendFilters = (): void => {
    const data: Record<string, string> = {} // Usamos un tipo específico para el objeto data

    if (valueTwo) {
      data.end_date = dayjs(valueTwo).format('YYYY-MM-DD')
    }

    if (value) {
      data.start_date = dayjs(value).format('YYYY-MM-DD')
    }

    if (age) {
      data.type = age
    }

    if (Object.keys(data).length === 0) {
      ErrorToast('No hay datos para enviar.')
      return
    }
    getVisitsData({...filters, ...data})
  }

  const TypeEnum: string[] = ['Rapid Pass', 'Proveedor', 'Frecuente', 'Manual']

  useEffect(() => {
    getVisitsData(filters)
  }, [])

  return (
    <HomeTemplate>
      <Box
        sx={{
          background: 'white',
          padding: '10px 10px',
          borderRadius: '25px',
          marginBottom: '20px'
        }}
      >
        <Container maxWidth='lg'>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                    label='Fecha inicial'
                    value={value}
                    maxDate={dayjs()}
                    onChange={(newValue) => {
                      setValue(newValue)
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker', 'DatePicker']}>
                  <DatePicker
                    label='Fecha final'
                    maxDate={dayjs()}
                    value={valueTwo}
                    onChange={(newValue) => {
                      setValueTwo(newValue)
                    }}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={{ marginTop: 1, width: '100%' }}>
                <InputLabel id='demo-simple-select-helper-label'>
                  Busqueda avanzada
                </InputLabel>

                <Select
                  labelId='demo-simple-select-helper-label'
                  id='demo-simple-select-helper'
                  value={age}
                  label='Busqueda avanzada'
                  onChange={handleChange}
                >
                  {TypeEnum?.map((item: any, index: number) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3} sx={{}}>
              <Box sx={{ paddingLeft: '25px', marginTop: '10px' }}>
                <MainButton
                  onClick={sendFilters}
                  text='Buscar'
                  color='#023B59'
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Wrapped title='Visitas'>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'transparent' }}>
            <Tabs
              value={valueTab}
              onChange={handleChangeTab}
              aria-label='basic tabs example'
            >
              <Tab label='Activos' {...a11yProps(0)} />
              <Tab label='Historial' {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={valueTab} index={0}>
            <Box
              sx={{
                width: '80%',
                margin: 'auto',
                height: '80%',
                overflowY: 'auto',
                padding: '10px'
              }}
            >
              {!loading ? (
                <>
                  {visits?.map((item: VisitData, index: number) => (
                    <>
                      <Grid
                        key={index}
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <CardInfo key={index} title={item?.type}>
                          <p>Motivo: {item?.reason ? item.reason : '-'}</p>
                          <p>Fecha y hora ingreso: {item?.date}</p>
                          <p>Nombre: {item?.name ? item.name : '-'}</p>
                          <p>
                            Identificación:{' '}
                            {item.identification ? item.identification : '-'}
                          </p>
                          <p>Observaciones: -</p>
                        </CardInfo>
                      </Grid>
                      <hr />
                    </>
                  ))}
                </>
              ) : (
               <Box sx={{display: 'flex', justifyContent: 'center'}}>
                         <img width={60} src={load} alt="load" />
                      </Box>
              )}
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={valueTab} index={1}>
            <Box
              sx={{
                width: '80%',
                margin: 'auto',
                height: '80%',
                overflowY: 'auto',
                padding: '10px'
              }}
            >
              {!loading ? (
                <>
                  {visits?.map((item: VisitData, index: number) => (
                    <>
                      <Grid
                        key={index}
                        container
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        <CardInfo key={index} title={item?.type}>
                          <p>Motivo: {item?.reason ? item.reason : '-'}</p>
                          <p>Fecha y hora ingreso: {item?.date}</p>
                          <p>Nombre: {item?.name ? item.name : '-'}</p>
                          <p>
                            Identificación:{' '}
                            {item.identification ? item.identification : '-'}
                          </p>
                          <p>Observaciones: -</p>
                        </CardInfo>
                      </Grid>
                      <hr />
                    </>
                  ))}
                </>
              ) : (
               <Box sx={{display: 'flex', justifyContent: 'center'}}>
                         <img width={60} src={load} alt="load" />
                      </Box>
              )}
            </Box>
          </CustomTabPanel>
        </Box>
      </Wrapped>
    </HomeTemplate>
  )
})

Visits.displayName = 'Visits'
export default Visits
