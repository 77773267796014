import React, { memo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// import Icon from '../../../../../components/icons'
import type { SidebarOptionI } from '../../../../../types'
import './SidebarOption.scss'

interface SidebarOptProps {
  option: SidebarOptionI
  closed: boolean
}

const SidebarOption = memo(({ option, closed }: SidebarOptProps) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  let className = 'sidebar-item'
  if (option.url !== null && option.url !== undefined) {
    if (pathname.includes(option.url)) className += ' active'
    if (option.disabled) className += ' disabled'
  }
  if (closed !== null && closed !== undefined) {
    if (closed) className += ' closed'
  }

  const handleClick = (): void => {
    if (option.disabled) return
    if (option.onClick !== null && option.onClick !== undefined) {
      option.onClick()
      return
    }
    if (option.url !== null && option.url !== undefined) {
      navigate(option.url)
    }
  }

  return (
    <div className={className} onClick={handleClick}>

      <img src={option.icon} alt="" />
      <label>{option.label}</label>
      {option.url !== null &&
        option.url !== undefined &&
        pathname.includes(option.url) && (
          <div className='active-section'>
          </div>
        )}
    </div>
  )
})
SidebarOption.displayName = 'SidebarOption'
export default SidebarOption
