import { createSlice } from '@reduxjs/toolkit'

export interface AuthData {
  token: string
  error: string
  expireToken?:number
}

const initialState: AuthData = {
  token: '',
  error: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserSession: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    setLoginError: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
  }
})

// Metodo global para actualizar el state
export const { setUserSession, setLoginError } = authSlice.actions

export default authSlice.reducer
