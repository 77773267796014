import { AUTHSERVICE } from '../config'

export const getUserByStatus = async (status: string): Promise<any> => {
    return await AUTHSERVICE().get(`/admin/residents?status=${status}`)
  }

  export const getUserByPagination = async (page: string, perPage: string): Promise<any> => {
    return await AUTHSERVICE().get(`/admin/residents?per_page=${perPage}&page=${page}`)
  }

  export const getUserByFilters = async (page: string, perPage: string, status: string): Promise<any> => {
    return await AUTHSERVICE().get(`/admin/residents?per_page=${perPage}&page=${page}&status=${status}`)
  }

  export const updatedStatusUser = async (id: number, data: any): Promise<any> => {
    return await AUTHSERVICE().put(`/admin/residents/${id}`, data)
  }

  export const getUserChart = async (): Promise<any> =>{
    return await AUTHSERVICE().get('admin/users/chart')
  }

  export const getUserProfile = async (): Promise<any> =>{
    return await AUTHSERVICE().get('admin/profile')
  }