import { useEffect, useState } from 'react'
// import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { SidebarBotOptions, SidebarTopOptions } from '../../../constants'
// import { useDecodeToken, SessionData } from '../../../helpers/useDecodeToken'
import type { SidebarOptionI } from '../../../types'

export const useHomeTemplate = (): any => {
  const { pathname } = useLocation()
  const [closed, setClosed] = useState(false)
  const [className, setClassname] = useState('content-layout')
  // const dispatch = useDispatch()


  useEffect(() => {
    const checkIfClosed = (): void => {
      if (closed) { setClassname(`${className} closed`); return; }
      setClassname(`content-layout`);
    }
    checkIfClosed()
  }, [closed])

  const handleClickSidebar = (): void => {
    setClosed(!closed)
  }

  const getNavbarTitle = (): string => {
    const newArray: SidebarOptionI[] = [
      ...SidebarBotOptions,
      ...SidebarTopOptions
    ]
    const Title = newArray.filter(
      (option: SidebarOptionI): SidebarOptionI | undefined => {
        if (option.url !== undefined && pathname.includes(option.url)) {
          return option
        }
        return undefined
      }
    )
    if (Title !== undefined && Title?.length > -1) return Title[0]?.label
    return ''
  }

  return {
    closed,
    className,
    handleClickSidebar,
    getNavbarTitle
  }
}