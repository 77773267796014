import React, { memo, useEffect, useState } from 'react'
import HomeTemplate from '../../template/HomeTemplate/HomeTemplate'
import './Surveillance.scss'
import Wrapped from '../../components/Wrapped/Wrapped'
import {
  Box,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Tooltip,
  Modal,
  Backdrop,
  Fade
} from '@mui/material'
import { useSurveillance } from './hooks/useSurveillance'

import editpwd from '../../assets/svg/editpw.svg'

// import editdata from '../../assets/svg/editdata.svg'
import MainButton from '../../components/MainButton/MainButton'
import CreateSurveillance from './Create/CreateGuard'
import load from '../../assets/loading.gif'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: '600px',
  bgcolor: 'background.paper',
  borderRadius: '26px',
  paddingLeft: 4,
  paddingRight: 4,
  paddingTop: 2,
  paddingBottom: 2
}
const Surveillance = memo(() => {
  const { getGuardsData, guards, loading } = useSurveillance()
  const [open, setOpen] = useState(false)

  const handleClickOpen = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  useEffect(() => {
    getGuardsData()
  }, [])

  const goToEdit = (idGuard: string): void => {
    if (typeof window !== 'undefined') {
      window.open(`/vigilancia/asignar-contraseña/${idGuard}`, '_self')
    }
  }

  return (
    <HomeTemplate>
      <Grid container spacing={2}>
        <Grid xs={8}>
          <Wrapped title='Vigilancia'>
            <Box sx={{ width: '100%' }}>
              {!loading ? (
                <TableContainer component={Paper} sx={{ marginBottom: '30px' }}>
                  <Table aria-label='simple table'>
                    <TableHead sx={{ marginBottom: '10px' }}>
                      <TableRow>
                        <TableCell># Caseta</TableCell>
                        <TableCell align='center'>Correo</TableCell>
                        <TableCell align='center'>Teléfono</TableCell>
                        <TableCell align='center'>Acciones</TableCell>
                      </TableRow>
                    </TableHead>
                    <br />
                    <TableBody
                      sx={{
                        marginBottom: '10px'
                      }}
                    >
                      {guards?.length > 0 &&
                        guards?.map((item: any, index: number) => (
                          <TableRow
                            key={index}
                            sx={{
                              '&:last-child td, &:last-child th': {
                                borderRadius: 0
                              }
                            }}
                          >
                            <TableCell component='th' scope='item?'>
                              {item?.name}
                            </TableCell>
                            <TableCell align='center'>{item?.email}</TableCell>
                            <TableCell align='center'>
                              {item?.phone_number}
                            </TableCell>

                            <TableCell align='center'>
                              <Tooltip title='Asignar una contraseña'>
                                <button
                                  style={{
                                    border: 'none',
                                    background: 'transparent',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    goToEdit(item?.id)
                                  }}
                                >
                                  <img src={editpwd} alt='' />
                                </button>
                              </Tooltip>
                              {/* <Tooltip title='Editar datos'>
                                <button
                                  style={{
                                    border: 'none',
                                    background: 'transparent',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    goToEdit(item?.id)
                                  }}
                                >
                                  <img src={editdata} alt='' />
                                </button>
                              </Tooltip> */}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                         <img width={60} src={load} alt="load" />
                      </Box>
              )}
            </Box>
          </Wrapped>
        </Grid>
        <Grid xs={4} sx={{ paddingLeft: 3, paddingRight: 3}}>
          <MainButton
            type='submit'
            text='Crear caseta'
            color='#023B59'
            onClick={handleClickOpen}
          />
        </Grid>
      </Grid>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500
          }
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <CreateSurveillance handleClose={handleClose} getGuardsData={getGuardsData} />
          </Box>
        </Fade>
      </Modal>
    </HomeTemplate>
  )
})

Surveillance.displayName = 'Surveillance'
export default Surveillance
