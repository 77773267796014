import React, { memo } from "react";
import AuthTemplate from "../../../template/AuthTemplate/AuthTemplate";
import { Box } from "@mui/material";
import wha from '../../../assets/svg/whatsapp.svg'
import email from '../../../assets/svg/mail.svg'
import phone from '../../../assets/logos/ring-phone.png'
const SupportAuth = memo(() => {
    return (<AuthTemplate maxSize='large'>
        <Box sx={{ color: '#000', textAlign: 'center' }}>
            <h4>Comunicate con un ejecutivo de atención al cliente</h4>
            <Box sx={{}}>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px'}}>
                <a style={{display: 'flex', justifyContent: 'start', alignItems: 'center', color: 'black', outline: 'none', textDecoration: 'none'}} href="https://wa.link/m5fli2">
                    <img src={wha} alt="" />
                    Whatsapp
                </a>
                <a style={{display: 'flex', justifyContent: 'start', alignItems: 'center', color: 'black', outline: 'none', textDecoration: 'none'}} href="mailto:soporte@akcedo.com">
                    <img src={email} alt="" />
                    soporte@akcedo.com
                </a>

                <a style={{display: 'flex', justifyContent: 'start', alignItems: 'center', color: 'black', outline: 'none', textDecoration: 'none'}} href="tel:+503 2124-0726">
                    <img width={40} src={phone} alt="" />
                    2124-0726
                </a>    
                </Box>
            </Box>
        </Box>
    </AuthTemplate>
    )
});

SupportAuth.displayName = "SupportAuth"
export default SupportAuth