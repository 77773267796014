import { AUTHSERVICE } from '../config'

export const getDataGuards = async (): Promise<any> => {
    return await AUTHSERVICE().get('/admin/guard?target=guard')
  }

  export const createDataGuards = async (data: any): Promise<any> => {
    return await AUTHSERVICE().post('/admin/guard', data)
  }

  export const getGuardId = async (id: string): Promise<any> => {
    return await AUTHSERVICE().get(`/admin/guard/${id}`)
  }

  export const updatePwGuard = async (id: string, data: any): Promise<any> => {
    return await AUTHSERVICE().put(`/admin/guard/${id}`, data)
  }