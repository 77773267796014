import React, { memo } from 'react'
import './MainButton.scss'

interface ButtonProps {
  onClick: () => void
  color?: string
  text: string
  type?: 'button' | 'submit' | 'reset';
}

const MainButton = memo(({ onClick, color = '#000', text, type = 'button' }: ButtonProps) => {
  const handleClick = (): void => {
    onClick()
  }
  return (
    <button
      className='main___button'
      style={{ backgroundColor: color }}
      onClick={handleClick}
      type={type}
    >
      {text}
    </button>
  )
})

MainButton.displayName = 'MainButton'
export default MainButton
