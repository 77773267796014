import React, { memo, useEffect } from 'react';
import HomeTemplate from '../../../template/HomeTemplate/HomeTemplate';
import Wrapped from '../../../components/Wrapped/Wrapped';
import { Box, FormControl, Grid, TextField } from '@mui/material';
import MainButton from '../../../components/MainButton/MainButton';
import { useForm, type SubmitHandler } from 'react-hook-form';
import { useSurveillance } from '../hooks/useSurveillance';
import { useParams } from 'react-router-dom';

interface FormValues {
  password: string;
  confirmPassword: string;
};

const EditPwSurveillance = memo(() => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormValues>();
  const {getGuardById, updatePassword} = useSurveillance()
  const { id } = useParams();
  console.log(id)

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    console.log(data);

    const dataUpdated = {
      password: data.password
    }

    updatePassword(id, dataUpdated)

  };

  const password = watch('password');
  // const confirmPassword = watch('confirmPassword');

  useEffect(() => {
    getGuardById('33')
  }, [])

  return (
    <HomeTemplate>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Wrapped title='Editar contraseña'>
            <Box sx={{ width: '50%', margin: 'auto' }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl sx={{ width: '100%', marginTop: '25px' }} variant='outlined'>
                  <TextField
                    id='password'
                    label='Contraseña'
                    type='password'
                    variant='standard'
                    {...register('password', { required: 'Este campo es requerido' })}
                  />
                  {errors.password && <p style={{color: '#dc2626'}}>{errors.password.message}</p>}
                </FormControl>

                <FormControl sx={{ width: '100%', marginTop: '25px' }} variant='outlined'>
                  <TextField
                    id='confirmPassword'
                    label='Confirmar Contraseña'
                    type='password'
                    variant='standard'
                    {...register('confirmPassword', {
                      required: 'Este campo es requerido',
                      validate: (value) =>
                        value === password || 'Las contraseñas no coinciden',
                    })}
                  />
                  {errors.confirmPassword && <p style={{color: '#dc2626'}}>{errors.confirmPassword.message}</p>}
                </FormControl>

                <Box sx={{ width: '30%', margin: 'auto', marginTop: 8, marginBottom: 6 }}>
                  <MainButton type='submit' text='Editar' color='#023B59' onClick={() => {}} />
                </Box>
              </form>
            </Box>
          </Wrapped>
        </Grid>
      </Grid>
    </HomeTemplate>
  );
});

EditPwSurveillance.displayName = 'EditPwSurveillance';
export default EditPwSurveillance;
