import React, { memo, useEffect } from 'react'
import './Navbar.scss'
import { Link } from 'react-router-dom'
import support from '../../../../assets/svg/support.svg'
import { useHomeServices } from '../../../../views/Home/hooks/useHome'
// import H1 from '../../../../components/text/H1/H1'
// import { getUserData } from '../../../../services/auth/users'
// import { useDecodeToken } from '../../../../helpers/useDecodeToken'
// import { useDispatch, useSelector } from 'react-redux'
interface NavbarProps {
  title: string
}
const Navbar = memo(({ title }: NavbarProps) => {
  const { getUserProfileData, userProfile, loading } = useHomeServices()
  // const dispatch = useDispatch()
  // const { googleData }: AuthData = useSelector((store: AppStore) => store.auth)
  // const { decodeTokenData } = useDecodeToken()
  // const userData = decodeTokenData()

  // useEffect(() => {
  //   const getUserdata = async (): Promise<void> => {
  //     try {
  //       const result = await getUserData(userData.uid.id)
  //       const user = result.data
  //       const data = {
  //         ...googleData,
  //         mail: user.mail ?? '',
  //         name: user.name ?? '',
  //         phone: user.phone ?? '',
  //         developerName: user.developerName ?? '',
  //         imageUrl: user.avatar ?? ''
  //       }
  //       dispatch(setUserSession({ googleData: data }))
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   void getUserdata()
  // }, [])
  useEffect(() => {
    getUserProfileData()
  }, [])

  return (
    <div className='navbar'>
      <div className='content'>
        <div className='title'>
          {!loading && (
            <>
              <h3>Hola, {userProfile?.community?.name}</h3>
              <p>Administrador Res. Las Avellanas</p>
            </>
          )}
        </div>
        <div className='actions'>
          <Link to='/soporte'>
            <img src={support} alt='support' />
          </Link>
        </div>
      </div>
    </div>
  )
})
Navbar.displayName = 'Navbar'
export default Navbar
