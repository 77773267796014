import React, { memo, useEffect } from 'react'
import HomeTemplate from '../../template/HomeTemplate/HomeTemplate'
import './Statement.scss'
import Wrapped from '../../components/Wrapped/Wrapped'
import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material'
import MainButton from '../../components/MainButton/MainButton'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form'
import { useStatement } from './hooks/useStatement'
import { type ReportsData } from '../Reports/hooks/useReports'
import load from '../../assets/loading.gif'

const label = { inputProps: { 'aria-label': 'Checkbox demo' } }

interface StatementForm {
  title: string
  description: string
  target: string[] // Array of selected targets
}

const Statement = memo(() => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<StatementForm>({
    resolver: yupResolver(
      yup
        .object({
          title: yup.string().required('El título es requerido'),
          description: yup.string().required('La descripción es requerida'),
          target: yup
            .array()
            .min(1, 'Selecciona al menos un objetivo')
            .required('Selecciona al menos un objetivo')
        })
        .required()
    ),
    defaultValues: {
      title: '', // Establece valores iniciales vacíos
      description: '',
      target: [] // Inicializa el campo target como un array vacío
    }
  })

  const { handleStatement, getStatement, loadingData, statements } =
    useStatement()

  const sendDataLogin = async (form: StatementForm): Promise<void> => {
    const dataForm = {
      title: form.title,
      description: form.description,
      target: form.target,
      notification_type_id: 3
    }
    handleStatement(dataForm)
    reset()
    await getStatement()
  }

  useEffect(() => {
    getStatement()
  }, [])

  return (
    <HomeTemplate>
      <Wrapped title='Enviar comunicado'>
        <form onSubmit={handleSubmit(sendDataLogin)}>
          <div>
            <Box
              sx={{
                width: '80%',
                margin: 'auto',
                height: '80%',
                overflowY: 'auto',
                padding: '10px'
              }}
            >
              Enviar comunicado para:
              <div>
                <FormControlLabel
                  label='Residentes'
                  control={
                    <Controller
                      name='target'
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          {...label}
                          sx={{
                            color: '#340283',
                            '&.Mui-checked': {
                              color: '#340283'
                            }
                          }}
                          value='user'
                          checked={value.includes('user')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              onChange([...value, 'user'])
                            } else {
                              onChange(value.filter((item) => item !== 'user'))
                            }
                          }}
                        />
                      )}
                    />
                  }
                />

                <FormControlLabel
                  label='Vigilancia'
                  control={
                    <Controller
                      name='target'
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          {...label}
                          sx={{
                            color: '#340283',
                            '&.Mui-checked': {
                              color: '#340283'
                            }
                          }}
                          value='guard'
                          checked={value.includes('guard')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              onChange([...value, 'guard'])
                            } else {
                              onChange(value.filter((item) => item !== 'guard'))
                            }
                          }}
                        />
                      )}
                    />
                  }
                />
              </div>
              <div style={{ marginTop: '10px' }}>
                <Controller
                  name='title'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      helperText={
                        errors?.title?.message ? errors?.title?.message : null
                      }
                      error={!!errors?.title?.message}
                      onChange={onChange}
                      value={value}
                      label='Asunto'
                      variant='standard'
                      type='text'
                      sx={{ width: '100%' }}
                    />
                  )}
                />
              </div>
              <div>
                <p>¡Escribe el mensaje para tu comunidad!</p>
                <Controller
                  name='description'
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <textarea
                      placeholder='Escribe el mensaje...'
                      onChange={onChange}
                      value={value}
                      style={{
                        width: '100%',
                        borderRadius: '6px',
                        borderColor: '#bababa',
                        resize: 'none',
                        padding: '10px'
                      }}
                      id=''
                      cols={30}
                      rows={10}
                    />
                  )}
                />
                {errors?.title?.message ? (
                  <p>{errors?.title?.message}</p>
                ) : null}
                <div style={{ width: '200px', marginTop: '10px' }}>
                  <MainButton
                    onClick={() => {}}
                    text='Enviar'
                    type='submit'
                    color='#023B59'
                  />
                </div>
              </div>
            </Box>
          </div>
        </form>
        <Box
          sx={{
            width: '80%',
            margin: 'auto',
            height: '80%',
            overflowY: 'auto',
            padding: '10px',
            marginTop: '10px'
          }}
        >
          {!loadingData ? (
            <>
              {statements.length > 0 &&
                statements.map((item: ReportsData, index: number) => (
                  <>
                  <Box key={index} sx={{m: 2}}>
                    <p>
                      <b>{item?.title}</b>
                    </p>
                    <p>{item?.description}</p>
                  </Box>
                  <hr />
                  </>
                  
                ))}
            </>
          ) : (
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                         <img width={60} src={load} alt="load" />
                      </Box>
          )}
        </Box>
      </Wrapped>
    </HomeTemplate>
  )
})

Statement.displayName = 'Statement'
export default Statement
