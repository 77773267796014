import React, { memo, useEffect } from 'react'
import HomeTemplate from '../../template/HomeTemplate/HomeTemplate'
import deny from '../../assets/svg/deny.svg'
import accept from '../../assets/svg/accept.svg'
import './Users.scss'
import Wrapped from '../../components/Wrapped/Wrapped'
import {
  Accordion,
  AccordionSummary,
  Box,
  AccordionDetails,
  Grid,
  Tabs,
  Tab
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CardInfo from '../../components/CardInfo/CardInfo'
import { Cell, Pie, PieChart, Tooltip } from 'recharts'
import { useUsersApp } from './hooks/useUsers'

import load from '../../assets/loading.gif'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const Users = memo(() => {
  const { userByStatus, getUserChartData, loading, userStatus, handleUpdateStatus, userChart } = useUsersApp()
  const [value, setValue] = React.useState(0)

  useEffect(() => {
    userByStatus('approved')
    getUserChartData()
  }, [])
  const CustomTabPanel: React.FC<TabPanelProps> = (props) => {
    const { children, value, index, ...other } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    )
  }

  const a11yProps: any = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: number
  ): void => {
    setValue(newValue)
    console.log(newValue)
    if (newValue === 0) userByStatus('approved')
    else if (newValue === 1) userByStatus('pending')
    else if (newValue === 2) userByStatus('declined')
  }

  const data = [
    { name: 'Casas registradas', value: 80 },
    { name: 'Casas totales', value: 20 }
  ]
  const COLORS = ['#B8D6DD', '#1A5B8B']

  const acceptUser = (id: number): void => {
    setValue(0)
    userByStatus('approved')
    handleUpdateStatus(id, {
      approved: true
    })
  }
  const declineUser = (id: number): void => {
    setValue(0)
    userByStatus('approved')
    handleUpdateStatus(id, {
      approved: false
    })
  }

  return (
    <HomeTemplate>
      <Grid container spacing={2}>
        <Grid xs={8}>
          <Wrapped title='Residentes'>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'transparent' }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label='basic tabs example'
                >
                  <Tab label='Activos' {...a11yProps(0)} />
                  <Tab label='Recientes' {...a11yProps(1)} />
                  <Tab label='Restringidos' {...a11yProps(2)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Box
                  sx={{
                    width: '100%',
                    margin: 'auto',
                    height: '60%',
                    overflowY: 'auto',
                    padding: '10px'
                  }}
                >
                  {!loading ? (
                    <>
                      {userStatus?.map((item: any, index: number) => (
                        <Accordion key={index} sx={{ marginTop: '10px' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <div className='header-collapse'>
                              <img src='https://placehold.co/600x400' alt='' />
                              <h2>{item?.name}</h2>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CardInfo title='Datos personales'>
                              <p>
                                <span>Nombre: </span>
                                {item?.name}
                              </p>
                              <p>
                                <span>Teléfono: </span>
                                {item?.phone_number}
                              </p>
                              <p>
                                <span>Correo: </span>
                                {item?.email}
                              </p>
                              <p>
                                <span>Casa: </span>
                                {item?.community?.pivot?.house_number}
                              </p>
                            </CardInfo>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </>
                  ) : (
                      <Box sx={{display: 'flex', justifyContent: 'center'}}>
                         <img width={60} src={load} alt="load" />
                      </Box>
                    
                  )}
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Box
                  sx={{
                    width: '100%',
                    margin: 'auto',
                    height: '60%',
                    overflowY: 'auto',
                    padding: '10px'
                  }}
                >
                  {!loading ? (
                    <>
                      {userStatus?.map((item: any, index: number) => (
                        <Accordion key={index} sx={{ marginTop: '10px' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'space-between',
                                position: 'relative'
                              }}
                            >
                              <div className='header-collapse'>
                                <img
                                  src='https://placehold.co/600x400'
                                  alt=''
                                />
                                <h2>{item?.name}</h2>
                              </div>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails sx={{display: 'flex', justifyContent: 'space-around'}}>
                            <CardInfo title='Datos personales'>
                              <p>
                                <span>Nombre: </span>
                                {item?.name}
                              </p>
                              <p>
                                <span>Teléfono: </span>
                                {item?.phone_number}
                              </p>
                              <p>
                                <span>Correo: </span>
                                {item?.email}
                              </p>
                              <p>
                                <span>Casa: </span>
                                {item?.community?.pivot?.house_number}
                              </p>
                            </CardInfo>
                            <div className='options-collapse'>
                              <p>Acciones:</p>
                                <button onClick={() => {acceptUser(item?.id)}}>
                                  <img src={accept} alt='btn' />
                                </button>
                                <button onClick={() => {declineUser(item?.id)}}>
                                  <img src={deny} alt='btn' />
                                </button>
                              </div>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </>
                  ) : (
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <img width={60} src={load} alt="load" />
                 </Box>
                  )}
                </Box>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Box
                  sx={{
                    width: '100%',
                    margin: 'auto',
                    height: '60%',
                    overflowY: 'auto',
                    padding: '10px'
                  }}
                >
                  {!loading ? (
                    <>
                      {userStatus?.map((item: any, index: number) => (
                        <Accordion key={index} sx={{ marginTop: '10px' }}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                          >
                            <div className='header-collapse'>
                              <img src='https://placehold.co/600x400' alt='' />
                              <h2>{item?.name}</h2>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <CardInfo title='Datos personales'>
                              <p>
                                <span>Nombre: </span>
                                {item?.name}
                              </p>
                              <p>
                                <span>Teléfono: </span>
                                {item?.phone_number}
                              </p>
                              <p>
                                <span>Correo: </span>
                                {item?.email}
                              </p>
                              <p>
                                <span>Casa: </span>
                                {item?.community?.pivot?.house_number}
                              </p>
                            </CardInfo>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </>
                  ) : (
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                         <img width={60} src={load} alt="load" />
                      </Box>
                  )}
                </Box>
              </CustomTabPanel>
            </Box>
          </Wrapped>
        </Grid>
        <Grid item xs={4} style={{ paddingTop: 0 }}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '25px',
              padding: '10px 15px'
            }}
          >
            <div className='wrapper-content'>
              <h1>Residentes</h1>
              <PieChart width={300} height={250}>
                <Pie
                  data={userChart}
                  dataKey='value'
                  nameKey='name'
                  cx={100}
                  cy={120}
                  innerRadius={60}
                  outerRadius={80}
                  fill='#8884d8'
                  paddingAngle={5}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </div>
          </Box>
        </Grid>
      </Grid>
    </HomeTemplate>
  )
})

Users.displayName = 'Users'
export default Users
