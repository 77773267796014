import { Container } from "@mui/material";
import React, { memo } from "react";

import back from '../../../assets/logos/back.png'

const Privacy = memo(() => {
    
    return (<>
    <Container sx={{ paddingTop: 6, paddingBottom: 6 }}>
        <h1 style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '12px' }}> <a href="/"><img width={40} src={back} alt="" /></a> Política de privacidad y datos</h1>
        <p>Última actualización: 21 de agosto de 2023</p>
          <b>Akcedo Technologies SA de CV</b>
          <p>
            Esta política describe cómo tratamos la información personal en los
            sitios Web y/o aplicaciones. También se aplica a nuestros sitios y
            aplicaciones móviles. Al interactuar con nosotros en nuestros sitios
            y plataformas, aceptas los términos de esta política. Seguridad:
          </p>
          <p>
            Akcedo Technologies SA de CV implementa medidas de seguridad para
            proteger los datos personales almacenados frente a la manipulación
            involuntaria o deliberada, la pérdida o la destrucción y el acceso
            por parte de personas no autorizadas. Nuestras medidas de seguridad
            mejoran continuamente.
          </p>
          <p>Motivos para los que se recopilan datos personales.</p>
          <ul>
            <li>Registro de residentes de las comunidades residenciales</li>
            <li>Control de accesos de visitantes o proveedores.</li>
            <li>Historial de visitas en las comunidades residenciales.</li>
            <li>
              Verificación del visitante o proveedor por parte de los guardias
              de seguridad.
            </li>
            <li>
              Base de datos para la verificación por parte de los
              administradores.
            </li>
          </ul>
          <b>Base de datos</b>

          <p>
            La información que tú nos proporcionas y que recopilamos en forma
            electrónica se guarda en nuestras bases de datos, las cuales son de
            nuestra propiedad. Los datos los conservamos por un período máximo
            de 5 años. Las bases de datos están encriptadas y protegidas por
            contraseñas.{' '}
          </p>
          <p>
            Por cualquier duda o consulta sobre sus datos personales nos pueden
            contactar al:
          </p>
          <p>
            <b>Correo:</b> <a href='mailto:info@akcedo.com'></a>info@akcedo.com
          </p>
          <p>
            <b>Tel.</b> <a href='tel:+50360550815'></a>+50360550815.
          </p>
        </Container>
    </>)
})

Privacy.displayName = 'Privacy'
export default Privacy;