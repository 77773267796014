import React, { memo } from 'react'
import './AuthTemplate.scss'
import HeaderAuth from './components/HeaderAuth/HeaderAuth'
import FooterAuth from './components/FooterAuth/FooterAuth'
// import AuthFooter from '../../components/custom/AuthFooter/AuthFooter'
// import WelcomeScreen from './components/WelcomeScreen/WelcomeScreen'
interface AuthTemplateProps {
  children: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[]
  size?: string
  maxSize?: 'small' | 'large'
}
const AuthTemplate = memo(
  ({ children, size = '', maxSize = 'small' }: AuthTemplateProps) => {
    return (
      <div className='auth-template'>
        <div className='content' data-variation={maxSize}>
          <div data-variation={maxSize}>
            <HeaderAuth />
          </div>
          {children}
        </div>
        <FooterAuth />
      </div>
    )
  }
)
AuthTemplate.displayName = 'AuthTemplate'
export default AuthTemplate
