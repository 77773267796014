import { Box, Container, Grid } from '@mui/material'
import React, { memo } from 'react'
import './Wrapped.scss'
interface WrappedProps {
  title: string
  children: React.ReactNode | React.ReactNode[] | JSX.Element | JSX.Element[]
}
const Wrapped = memo(({ title, children }: WrappedProps) => {
  return (
    <Grid item xs={12}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '25px',
          padding: '10px 15px'
        }}
      >
        <div className='wrapped-content'>
          
          <Container maxWidth="lg">
            <h1>{title}</h1>
          <hr />
            {children}
          </Container>
          
        </div>
      </Box>
    </Grid>
  )
})

Wrapped.displayName = 'Wrapped'
export default Wrapped
