import { AUTHSERVICE } from '../config'

export const getTypesReports = async (): Promise<any> => {
    return await AUTHSERVICE().get('/admin/notification/types')
  }

export const getReports = async (filters: Record<string, string>): Promise<any> => {
    const queryParams = new URLSearchParams(filters).toString();
    console.log(queryParams)
    return await AUTHSERVICE().get(`/admin/notification?${queryParams}`)
}