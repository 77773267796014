import React, { memo, useEffect, useState } from 'react';
import HomeTemplate from '../../template/HomeTemplate/HomeTemplate';
import { Box, Grid } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

import './Home.scss';
import CardInfo from '../../components/CardInfo/CardInfo';
import MainInfo from './components/MainInfo/MainInfo';
import { type VisitData, useHomeServices } from './hooks/useHome';
import load from '../../assets/loading.gif'


const data = [
    { name: 'Casas registradas', value: 80 },
    { name: 'Casas totales', value: 20 },
  ];
  
  const COLORS = ['#B8D6DD', '#1A5B8B'];

const Home = memo(() => {

  const { 
    getVisitsData, 
    getReportsData, 
    getUserChartData, 
    getUserProfileData, 
    visits, 
    reports, 
    userChart, 
    userProfile, 
    loading 
  } = useHomeServices()
  const [filters] = useState({
    per_page: 3
  })

  useEffect(() => {
    getVisitsData()
    getReportsData(filters)
    getUserChartData()
    getUserProfileData()
  }, [])

  return (
    <HomeTemplate>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '25px',
              padding: '10px 20px'
            }}
          >
            <div className='wrapper-content'>
              <h1>Historial</h1>
              {
                !loading ? (
                  <>
                  {visits?.slice(0,3).map((item: VisitData, index: number) => (
                <CardInfo key={index} title={item?.reason}>
                  <p><span>Nombre: </span>{item.name}</p>
                  <p><span>Tipo: </span>{item.type}</p>
                  <p><span>Fecha y hora: </span>{item.date}</p>
                </CardInfo>
              ))}
                  </>
                ) : (<Box sx={{display: 'flex', justifyContent: 'center'}}>
                <img width={60} src={load} alt="load" />
             </Box>) 
              }
            </div>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '25px',
              padding: '10px 15px'
            }}
          >
            <div className='wrapper-content'>
              <h1>Reportes</h1>
              {reports?.map((item: any, index: number) => (
                <CardInfo key={index} title={item?.title}>
                  <p><span>Tipo: </span>{item.notification_type.name}</p>
                  <p><span>Fecha y hora: </span>{item.created_at}</p>
                  <p><span>Descripcion: </span>{item.description}</p>
                </CardInfo>
              ))}
            </div>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '25px',
              padding: '10px 15px'
            }}
          >
            <div className='wrapper-content'>
              <MainInfo infoResident={userProfile}/>
            </div>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '25px',
              padding: '10px 15px'
            }}
          >
            <div className='wrapper-content'>
              <h1>Residentes</h1>
                <PieChart width={300} height={250}>
                    <Pie
                    data={userChart}
                    dataKey="value"
                    nameKey="name"
                    cx={100}
                    cy={120}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    </Pie>
                    <Tooltip />
                </PieChart>
            </div>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '25px',
              padding: '10px 15px'
            }}
          >
            <div className='wrapper-content'>
              <h1>Vigilancia</h1>
              <p>No Casetas ingresadas aun</p>
            </div>
          </Box>
        </Grid>
      </Grid>
    </HomeTemplate>
  )
})

Home.displayName = 'Home'
export default Home
